import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { isMobile } from "react-device-detect";
import { AUTH_TOKEN } from '../token'
import ReactGA from 'react-ga';
import { Mutation } from 'react-apollo';
import axios from 'axios';

import Button from './Button'
import Icon from './Icon/Icon'

import twitterIcon from '../images/twitter.svg'
import instagramIcon from '../images/instagram.svg'
import headerTwitterIcon from '../images/header-twitter.svg'
import headerInstagramIcon from '../images/header-insta.svg'
import fullLogo from '../images/full-logo.svg'
import logoSingle from '../images/logo-single.svg'
import defaultUserImage from '../images/default-user.svg';
import { colors } from '../styles/defaultTheme';
import { formatUserName, productionConsoleCheck } from '../utils'

import Resizer from "react-image-file-resizer";
import cloudinaryConfig from '../cloudinaryConfig'
const unsignedUploadPreset = cloudinaryConfig.unsigned_upload_preset;

class Header extends Component {

    constructor(props) {
     super(props);
      this.state = { loading: false, renderModal: false, showDropdown: false, showPlaylistDropdown: false};
     }


   componentDidMount() {
       document.addEventListener('click', this.handleClickOutside, true);
   }

   componentWillUnmount() {
       document.removeEventListener('click', this.handleClickOutside, true);
   }


  renderUserImage = (user) => {
    if(user && user.image) {
      return (
        <div
          className={`header__user-info__image${this.props.smallScreensize ? " mobile-profile" : ""}`}
          style={{backgroundImage: `url(${user.image})`}}
        >
        </div>
      )
    } else {
      return (
        <div
          className={`header__user-info__default-image${this.props.smallScreensize ? " mobile-profile" : ""}`}
        >
        <img
          alt="default profile image"
          src={defaultUserImage}
          className="header__user-info__default-user"
        />
        </div>
      )
    }
  }

  handleUpdateClick = (user) => {
    if(this.props.homepage) {
      this.props.dispatch({payload: {updateModal: true, signupModal: true, name: user.name && user.name, location: user.location && user.location, email: user.email && user.email}})
      this.props.history.push('/user/' + user.id)
      if(this.props.smallScreensize) {
        this.setState({renderModal: false})
      }
    } else {
      if(this.props.smallScreensize) {
        this.setState({renderModal: false})
      }
      this.props.history.push('/user/' + user.id)
    }
  }

  renderModalHeader = (user, onboarding, ratingMade) => {
    return (
      <div className="modal-header__wrapper">
        <div className="modal-header__top-section">
          <Icon
            height="20px"
            width="20px"
            type="close"
            customClass="modal-header__close-icon"
            handleclick={() => this.setState({renderModal: false})}
            style={{opacity: isMobile && "1"}}
          />
          <Link to="/"><img className="modal-header__logo" alt="Rapchr" src={fullLogo} /></Link>
        </div>
        <div className="modal-header__content">
          <div className="modal-header__content-container">
            <p className="modal-header__content-msg">
              A social platform for music fans to explore and share their taste in hip-hop. Rapchr is being built
              for fans of <b>all eras</b>. Sign up to explore this <b>sneak peek</b>, and to start
              contributing to the growing community. You'll also get <b>early access</b> to the app
              when it drops later this year.
            </p>
          </div>
          {user && !ratingMade &&
            <div onClick={() => this.handleUpdateClick(user)} className="modal-header__user-info__wrapper">
              {this.renderUserImage(user)}
              <div className="modal-header__user-info__details">
                <p className="modal-header__user-info__name">{formatUserName(user.name)}</p>
                <p className="modal-header__user-info__location">{user.location}</p>
              </div>
            </div>
          }
          {user && ratingMade &&
            <a href={`/user/${user.id}`} className="modal-header__user-info__wrapper">
              {this.renderUserImage(user)}
              <div className="modal-header__user-info__details">
                <p className="modal-header__user-info__name">{formatUserName(user.name)}</p>
                <p className="modal-header__user-info__location">{user.location}</p>
              </div>
            </a>
          }
          {!user &&
            <div
              onClick={() => this.homepageAccessClick()}
              className="modal-header__content-sec"
            >
              <a customClass="header__login-button">
                <p className="modal-header__content-link">Get Access</p>
              </a>
            </div>
          }
          {((user && this.props.profile) || (user && !onboarding && !this.props.ratingPage && !this.props.errorPage)) && <div className="modal-header__content-sec">
            <Link className="modal-header__link-wrapper" to="/rate"><p className="modal-header__content-link">Rate</p></Link>
          </div>}
          {user && (this.props.ratingPage || this.props.profile) && onboarding && !this.props.errorPage && <div className="modal-header__content-sec">
            <Link className="modal-header__link-wrapper" to={!user.onboardedSkills ? "/update-skills" : "/update-rappers"}><p className="modal-header__content-link">Finish Profile</p></Link>
          </div>}
          {!onboarding && !this.props.errorPage && <div className="modal-header__content-sec">
            <Link className="modal-header__link-wrapper" to="/list-builder"><p className="modal-header__content-link list-builder">List Builder</p></Link>
          </div>}

          <div className="modal-header__content-sec">
            <a onClick={() => this.setState({showDropdown: !this.state.showDropdown})} className="modal-header__link-wrapper"><p className="modal-header__content-link">Blog</p></a>
          </div>
          {this.state.showDropdown && <div className="modal-header__content-sec dropdown-option">
            <Link className="modal-header__link-wrapper" to="/posts"><p className="modal-header__content-link">New Music</p></Link>
          </div>}
          <div className="modal-header__content-sec">
            <a onClick={() => this.setState({showPlaylistDropdown: !this.state.showPlaylistDropdown})} className="modal-header__link-wrapper"><p className="modal-header__content-link">Playlist</p></a>
          </div>
          {this.state.showPlaylistDropdown && <div className="modal-header__content-sec dropdown-option">
            <a target="_blank" className="modal-header__link-wrapper" href="https://open.spotify.com/playlist/4ErsZhReXf05BZwPwEl4Q3?si=fxPE31nqSz6q15r3wBEHeQ"><p className="modal-header__content-link">Spotify</p></a>
          </div>}
          {this.state.showPlaylistDropdown && <div className="modal-header__content-sec dropdown-option">
            <a target="_blank" className="modal-header__link-wrapper" href="https://music.apple.com/us/playlist/the-rotation/pl.u-zPyLW43CZ6A8J8"><p className="modal-header__content-link">Apple Music</p></a>
          </div>}
          {this.state.showPlaylistDropdown && <div className="modal-header__content-sec dropdown-option">
            <a target="_blank" className="modal-header__link-wrapper" href="https://tidal.com/playlist/6ae53add-c615-4b9c-9e4b-86c5bb8653ec"><p className="modal-header__content-link">Tidal</p></a>
          </div>}

          <div className="modal-header__content-sec">
            <a className="modal-header__link-wrapper" href="mailto:support@joinrapchr.com"><p className="modal-header__content-link">Contact Us</p></a>
          </div>
          <div className="modal-header__content-sec">
            <Link className="modal-header__link-wrapper" to="/terms-of-use"><p className="modal-header__content-link">Terms of Use</p></Link>
          </div>
          <div className="modal-header__content-sec">
            <Link className="modal-header__link-wrapper" to="/privacy-policy"><p className="modal-header__content-link">Privacy Policy</p></Link>
          </div>
          {user &&
            <div
              onClick={() => {
                this.handleGAEvent("Logout", "User logged out", "header", user.email)
                localStorage.removeItem(AUTH_TOKEN)
                window.location.replace('/');
              }}
              className="modal-header__content-sec"
            >
              <a customClass="header__login-button">
                <p className="modal-header__content-link">Logout</p>
              </a>
            </div>
          }
          <div className="modal-header__socials">
            <a onClick={() => this.handleGAEvent("Social Outbound", "User Sent to Twitter", "header", user.email)} target="_blank" rel="noreferrer" href="https://twitter.com/rapchr" className="header__cta"><img className="modal-header__social-icon header__cta" alt="Twitter" src={twitterIcon} /></a>
            <a onClick={() => this.handleGAEvent("Social Outbound", "User Sent to Instagram", "header", user.email)} target="_blank" rel="noreferrer" href="https://www.instagram.com/joinrapchr/?hl=en" className="header__cta"><img className="modal-header__social-icon header__cta insta" alt="Instagram" src={instagramIcon} /></a>
          </div>
        </div>
        <div class="bump"></div>
      </div>
    )
  }

  renderMobileHeader = () => {
    return (
      <div className={`mobile-header__wrapper ${this.props.customClass ? this.props.customClass : ""}`}>
        <Icon
          width="25px"
          height="20px"
          color={colors.beachHouse}
          type="menu"
          customClass="mobile-header__menu-icon"
          handleclick={() => this.setState({renderModal: true})}
          style={{zIndex: this.props.graphicPage ? "4" : ""}}
        />
        {!this.props.signupScreen &&<div className="mobile-header__logo-wrapper">
          <Link to="/"><img className="mobile-header__logo" alt="Rapchr" src={fullLogo} /></Link>
        </div>}
      </div>
    )
  }

  renderDropdown = () => {
    if(this.state.showDropdown && !this.props.smallScreensize) {
      return (
        <div ref={this.setWrapperRef} className="header__filter-dropdown">
          <Link to="/posts" onClick={() => this.setState({showDropdown: false})} className="header__filter-option">New Music</Link>
        </div>
      )
    }
  }

  renderPlaylistDropdown = () => {
    if(this.state.showPlaylistDropdown && !this.props.smallScreensize) {
      return (
        <div ref={this.setWrapperRef} className="header__filter-dropdown playlist">
          <a target="_blank" href="https://open.spotify.com/playlist/4ErsZhReXf05BZwPwEl4Q3?si=fxPE31nqSz6q15r3wBEHeQ" className="header__filter-option">Spotify</a>
          <a target="_blank" href="https://music.apple.com/us/playlist/the-rotation/pl.u-zPyLW43CZ6A8J8" className="header__filter-option">Apple Music</a>
          <a target="_blank" href="https://tidal.com/playlist/6ae53add-c615-4b9c-9e4b-86c5bb8653ec" className="header__filter-option">Tidal</a>
        </div>
      )
    }
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
        this.setState({
            showDropdown: false,
            showPlaylistDropdown: false
        });
    }
  }

  handleGAEvent = (category, action, label, userId) => {
    if(process.env.REACT_APP_PRODUCTION) {
      ReactGA.event({
        userId: userId,
        category: category,
        action: action,
        label: label
      })
    }
  }

  handleGAError = (description, userId, action) => {
    if(process.env.REACT_APP_PRODUCTION) {
      ReactGA.event({
        userId: userId,
        category: "Error",
        description: description,
        fatal: true,
        label: 'error',
        action: action
      })
    }
  }

  handleSignupClick = () => {
    window.location.replace('/get-access')
  }

  homepageAccessClick = (shareUserId) => {
    if(shareUserId) {
      this.props.history.replace('/get-access/' + shareUserId)
    } else {
      this.props.history.replace('/get-access')
    }

    if(this.props.dispatch) {
      this.props.dispatch({payload: {signupModal: true}})
      this.setState({renderModal: false})
    }
  }

  render() {

    const user = this.props.currentUser;
    const onboarding = this.props.onboarding;
    const authToken = localStorage.getItem(AUTH_TOKEN)
    const shareUserId = this.props.shareUserId
    const ratingMade = this.props.ratingMade


    if(this.state.renderModal && this.props.smallScreensize) {
      return this.renderModalHeader(user, onboarding, ratingMade)
    }

    if(this.props.smallScreensize) {
      return this.renderMobileHeader()
    }

    return (
      <div className={`header__wrapper ${this.props.customClass ? this.props.customClass : ""}${this.props.homepage ? " home-header" : ""}`}>
        <div className="header__ctas">
          <a onClick={() => this.handleGAEvent("Social Outbound", "User Sent to Twitter", "header", user.email)} target="_blank" href="https://twitter.com/rapchr" className="header__cta"><img className="header__icon header__cta" alt="Twitter" src={headerTwitterIcon} /></a>
          {this.renderDropdown()}
          <a onClick={() => this.handleGAEvent("Social Outbound", "User Sent to Instagram", "header", user.email)} target="_blank" href="https://www.instagram.com/joinrapchr/?hl=en" className="header__cta left-cta"><img className="header__icon header__cta" alt="Instagram" src={headerInstagramIcon} /></a>
          <a onClick={() => this.setState({showDropdown: !this.state.showDropdown})} className="header__cta header__cta__text" >Blog</a>
          <a onClick={() => this.setState({showPlaylistDropdown: !this.state.showPlaylistDropdown})} className="header__cta header__cta__text" >Playlist</a>
          {this.renderPlaylistDropdown()}
          {!onboarding && !this.props.errorPage &&  <Link className="header__cta header__cta__text list-builder" to="/list-builder">List Builder</Link>}
          {((user && this.props.profile) || (user && !onboarding && !this.props.ratingPage && !this.props.errorPage)) && <Link className="header__cta header__cta__text" to="/rate">Rate</Link>}
          {user && onboarding && (this.props.ratingPage || this.props.profile) &&  <Link className="header__cta header__cta__text" to={!user.onboardedSkills ? "/update-skills" : "/update-rappers"}>Finish Profile</Link>}
        </div>
        <div className="header__logo-wrapper">
            <Link to="/"><img className="header__logo-image" alt="Rapchr" src={fullLogo} /></Link>
        </div>
        <div className="header__ctas__right">

        </div>
        <div className="header__user-info__wrapper">
          {user && !this.props.smallScreensize && !ratingMade &&
            <div
              className="header__user-info__details"
              onClick={() => this.handleUpdateClick(user)}
            >
              <p
                className="header__user-info__name"
              >
                {formatUserName(user.name)}
              </p>
              {this.renderUserImage(user)}
            </div>
          }
          {user && !this.props.smallScreensize && ratingMade &&
            <a
              className="header__user-info__details"
              href={`/user/${user.id}`}
            >
              <p
                className="header__user-info__name"
              >
                {formatUserName(user.name)}
              </p>
              {this.renderUserImage(user)}
            </a>
          }
          {!user && !this.props.smallScreensize &&
            <Button
              handleclick={this.props.homepage ? () => this.homepageAccessClick(shareUserId) : () => this.props.history.replace(shareUserId ? '/get-access' + shareUserId : '/get-access')}
              customClass="header__login-button"
            >
              Get Access
            </Button>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
