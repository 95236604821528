import React, {useReducer} from 'react'
import { useHistory } from "react-router-dom";
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import WindowSizeListener from 'react-window-size-listener'
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import { isMobile } from "react-device-detect";
import MailchimpSubscribe from "react-mailchimp-subscribe"


import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import { handleArtistClick } from '../utils'
import { colors, fontSizes} from '../styles/defaultTheme';
import ErrorPage from '../components/ErrorPage';
import Header from '../components/Header';
import RecommendedUser from '../components/RecommendedUser';
import Footer from '../components/Footer';
import ArtistRatingModal from '../components/ArtistRatingModal';
import Button from '../components/Button';

import imagePlaceholder from '../images/blog-placeholder.png'
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import favArtistStepMessage from '../images/favorite-artists-msg.svg';
import mobileFavArtistStepMessage from '../images/other-users-artists-mobile.svg';
import loadingIcon from '../images/load-disk.png';
import copyIcon from '../images/copy-icon.svg';
import twitterShareIcon from '../images/twitter-share-icon.svg';
import facebookShareIcon from '../images/facebook-share-icon.svg';

import { useQuery, useMutation } from 'react-apollo-hooks';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  let ye = date.getFullYear();
  let mo = date.toLocaleString('default', { month: 'long' });
  let da = date.getDate();
  return `${mo} ${da}, ${ye}`
}

const renderPost = (currentUser, state, dispatch, history, alert, singlePost, loading, error, recentUsersError) => {

  if(error) {
    return (
      <div style={{position: "relative", width: (recentUsersError || isMobile) && "100%"}} className={`skill-step__left-hand post-page__left-hand  ${state.smallScreensize && state.showUsers && "blur"}`}>
        <p className="post-page__error-msg">Post Does Not Exist</p>
        <p onClick={() => history.push("/posts")} className="post-page__error-back">New Music</p>
        {!currentUser &&  renderActionItems(state, dispatch, history)}
      </div>
    )
  } else if(loading) {
    return (
      <div style={{position: "relative", width: (recentUsersError || isMobile) && "100%"}} className={`skill-step__left-hand post-page__left-hand  ${state.smallScreensize && state.showUsers && "blur"}`}>
        <div className="loading__wrapper">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>
      </div>
    )
  } else {
    const post = singlePost.post
      return (
      <div style={{width: recentUsersError && "100%"}} className={`skill-step__left-hand post-page__left-hand  ${state.smallScreensize && state.showUsers && "blur"}`}>
        <div className="left-hand__top-gradient"></div>
        <p onClick={() => history.push('/posts')} className="post-page__category"><span>{post.category}</span></p>
        <h2 className="post-page__title">{post.title}</h2>
        {post.image && <div style={{backgroundImage: `url(${post.image})`}} className="post-page__main-image"></div>}
        <div className="post-page__info-wrapper">
          <div className="post-page__byline">
            {post.byline.image && <div style={{backgroundImage: `url(${post.byline.image})`}} className="post-page__auth-image"></div>}
            <p className="post-page__auth-name">{post.byline.name}</p>
          </div>
          <p className="post-page__date">{formatDate(post.createdAt)}</p>
        </div>
        <div className="post-page__content">
          {post.lede && <p className="post-page__lede">{post.lede}</p>}
          {post.content && <p className="post-page__sub-lede">{post.content}</p>}
          {renderContent(state, post.components)}
        </div>
        <div className="post-page__footer">
          {renderShareItems(post, alert)}
          {post.artists && post.artists.length > 0 &&
            <div className="post-page__artists">
              <p className="post-page__related-title">Related Rappers:</p>
              <div className="post-page__artists__wrapper">
                {renderArtists(post.artists, state, dispatch, history)}
              </div>
            </div>
          }
          {!currentUser && renderActionItems(state, dispatch, history)}
        </div>
        <div className="bump"></div>
        <div className="left-hand__bottom-gradient"></div>
      </div>
    )
  }
}

const renderArtists = (artists, state, dispatch, history) => {
  return artists.map((artist, index) => {
    return (
      <div key={index}
        onClick={() => dispatch({payload: {originalArtist: artist, artistModal: true, excludes: [artist.id]}})}
        className="post-page__artist"
      >
        <div
          style={{backgroundImage: `url(${artist.smallImage ? artist.smallImage : artist.image})`}}
          className="post-page__artist__image"
        >
        </div>
        <p className="post-page__artist__name">
          {artist.name}
        </p>
      </div>
  )})
}

const renderShareItems = (post, alert) => {
  return (
    <div className={`post-page__share-wrapper ${isMobile ? " mobile-share" : ""}`}>
      <p className="post-page__share-txt post-page__share-item">Share:</p>
      <FacebookShareButton
        url={window.location.href}
        quote={`${post.title} #rapchr`}
        imageUrl={post.image}
        className="post-page__share-item"
      >
        {!isMobile && <img src={facebookShareIcon} style={{width: "44px"}}
           />}
       {isMobile && <FacebookIcon size={34}
         round />}
      </FacebookShareButton >
    <TwitterShareButton
        url={window.location.href}
        title={`${post.title} @rapchr`}
        imageUrl={post.image}
        className="post-page__share-item"
      >
        {!isMobile && <img src={twitterShareIcon} style={{width: "44px"}}
           />}
         {isMobile && <TwitterIcon size={34}
           round />}
      </TwitterShareButton >
      <div className="post-page__share-item  rating-graphic__copy-icon__wrapper">
      <img
        className={`rating-graphic__copy-icon__image`}
        alt="copy graphic link"
        src={copyIcon}
        onClick={() => {alert.show("Url copied!");navigator.clipboard.writeText(window.location.href)}}
      />
      </div>
    </div>
  )
}

const renderActionItems = (state, dispatch, history) => {
  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const SimpleForm = () => <MailchimpSubscribe url={url}/>


  return (
    <div className="post-page__action-items">
      <Button
        handleclick={() => history.push('/get-access')}
        customClass="rating-artist__rate-btn post-page__cta-btn"
        gradient={true}
      >
        Sign Up To Rate & Explore
      </Button>
      <p className="post-page__action-items__split">- or subscribe for launch updates -</p>
      <div className="post-page__sub-container">
        <p className="post-page__action-items__title">Join Our Waitlist</p>
        <p className="post-page__action-items__msg">Rapchr is a new social platform being built for rap fans of all eras and styles, this is just a taste of what you can expect! Subscribe now for early access when the app is released.</p>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            return (
              <div className="post-page__sub-wrapper">
                <SimpleForm url={url} onSubmitted={formData => subscribe(formData)} />
                {status === "sending" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>sending...</div>}
                {status === "error" && <div style={{ color: "#722a1f" + 10, marginBottom: "20px" }} dangerouslySetInnerHTML={{__html: message}}/>}
                {status === "success" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>Subscribed !</div>}
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

const renderArtistModal = (state, dispatch, allSkills, history, currentUser) => {

  if(state.originalArtist.id && state.artistModal) {
    return (
      <ArtistRatingModal
        state={state}
        dispatch={dispatch}
        allSkills={allSkills}
        artistSlug={state.originalArtist.slug}
        history={history}
        currentUser={currentUser}
        homepage={!currentUser}
        profile={currentUser}
        postPage={true}
      />
    )
  }
}

const renderContent = (state, components) => {
  return components.map((component, index) => {
    if(component.content.includes('spotify')) {
      let regex = /(http|ftp|https):\/\/([\w\-_]+(?:(?:\.[\w\-_]+)+))([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
      let url = component.content.match(regex)[0];
      let album = component.content.includes('album')
      return <iframe key={index} src={url} style={{borderRadius: "10px", width: "100%"}} height={album ? "300" : "80"} frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    } else if(component.content.includes('youtube')) {
      let regex = /(http|ftp|https):\/\/([\w\-_]+(?:(?:\.[\w\-_]+)+))([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
      let url = component.content.match(regex)[0];
      return <iframe key={index} style={{borderRadius: state.smallScreensize ? "4vw" : "2vw", width: "100%", height: state.smallScreensize ? "50vw" : "27vw"}} src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    } else {
      return <div key={index} dangerouslySetInnerHTML={{__html: component.content}}></div>
    }
  })
}

const onResize = (windowSize, state, dispatch) => {

  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }
}

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  smallScreensize: window.innerWidth <= 768,
  showUsers: false,
  artistModal: false,
  originalArtist: {},
  userArtistRatings: [],
  compareFilter: '',
  excludes: [],
  loading: false
})

const Post = ({match, currentUser, allSkills}) => {

  const [state, dispatch] = useReducer(reducer, initialState())
  const postSlug = match.params.slug
  const alert = useAlert();


  const {
    data: post,
    loading: postLoading,
    error: postError
  } = useQuery(POST_QUERY, {
    variables: {
      slug: postSlug
    }
  })

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  let history = useHistory();

  // if(loading) {
  //   return (
  //     <p>Loading</p>
  //   )
  // }
  //
  // if(error) {
  //   return <ErrorPage />
  // }

  const seoTitle = postLoading || postError ? "Rapchr | The Social Platform For Hip-Hop" : `${post.post.title} | Rapchr`
  const seoImage = post && post.post && post.post.image ? post.post.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png";

  return (
    <div className="post-page">
      <Helmet>
        <title>{seoTitle}</title>
        {post && post.lede && <meta name="description" content={post.lede} />}
        <meta propery="og:url" content={window.location.href} />
        <meta propery="og:title" content={seoTitle} />
        {post && post.lede && <meta name="og:description" content={post.lede} />}
        <meta propery="og:image"  content={seoImage} />
        <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
        <meta name="twitter:title" content={seoTitle} />
        {post && post.lede && <meta name="twitter:description" content={post.lede} />}
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@joinrapchr" />
      </Helmet>
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
      <Header
        customClass={state.smallScreensize && state.showUsers && "blur"}
        allSkills={allSkills}
        currentUser={currentUser}
        smallScreensize={state.smallScreensize}
        onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
        profile={true}
      />
      {renderArtistModal(state, dispatch, allSkills, history, currentUser)}
      {renderPost(currentUser, state, dispatch, history, alert, post, postLoading, postError, recentUsersError)}
      {((state.smallScreensize && state.showUsers) || !state.smallScreensize) &&
      <div
        style={{display: recentUsersError && "none"}} className="skill-step__right-hand"
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
      >
        <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
          <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
          <img lt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        <div className="skill-step__top-gradient"></div>
        <div className="skill-step__users">
          {recentUsersLoading && !state.smallScreensize &&
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          }
          {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
            return (
              <RecommendedUser
                key={index}
                user={user.node}
                artists={true}
                index={index}
                currentUser={currentUser}
                recentUsersLoading={recentUsersLoading}
                onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
                onArtistClick={(artist) => handleArtistClick(artist, history)}
                customClass={state.loading ? " loading-more" : ""}
              />
            )
          })}
        </div>
        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="skill-step__bottom-gradient"></div>
        <div className="bump"></div>
      </div>}
      {!state.showUsers && <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta">
        <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
        <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
      </div>}
      <Footer  customClass={state.artistModal && "modal-footer"} />
    </div>
  )
}


export default Post

const POST_QUERY = gql`
  query post($slug: String!){
    post(slug: $slug) {
      id
      title
      category
      lede
      content
      byline {
        name
        image
      }
      image
      components
      createdAt
      artists {
        id
        name
        image
        smallImage
        slug
        currentUserRatings {
          id
          ratings
          votesTurnedOn
          voteSum
          currentUserVote {
            id
            positive
          }
          user {
            id
            name
            email
            roles {
              name
            }
          }
        }
      }
    }
  }
`
