import React, { useReducer, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import axios from 'axios';
import ReactGA from 'react-ga';
import { AUTH_TOKEN } from '../token'


import Header from '../components/Header';
import ArtistRatingModal from '../components/ArtistRatingModal';
import SearchArea from '../components/SearchArea'
import RecommendedUser from '../components/RecommendedUser';
import RatingGraphic from '../components/RatingGraphic';
import ErrorPage from '../components/ErrorPage'
import Footer from '../components/Footer'
import Icon from '../components/Icon/Icon'
import ProfileArtist from '../components/ProfileArtist'
import Tooltip from '../components/Tooltip'
import NoUserGraphic from '../components/NoUserGraphic'
import SharePrompt from '../components/SharePrompt'

import WindowSizeListener from 'react-window-size-listener'
import LoadingBar from 'react-top-loading-bar';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import useOnclickOutside from "react-cool-onclickoutside";

import otherUsersMsg from '../images/our-growing-msg.svg';
import loadingIcon from '../images/load-disk.png';
import mobileFavArtistStepMessage from '../images/our-growing-msg-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import logoSingle from '../images/logo-single.svg'
import sidewaysMsg from '../images/sideways-msg.svg'
import getAcessMsg from '../images/get-access-msg.svg'
import signupCommunityMobile from '../images/growing-community-mobile.svg';
import fullLogo from '../images/full-logo.svg'
import elipse from '../images/gradient-elipse.svg';
import copyIcon from '../images/copy-icon.svg';
import shareIcon from '../images/share-icon.svg';
import twitterShareIcon from '../images/twitter-share-icon.svg';
import facebookShareIcon from '../images/facebook-share-icon.svg';


import { colors } from '../styles/defaultTheme';
import { handleExcludes, formatUserName, formatDate } from '../utils'

import {
  VOTE_MUTATION,
  VOTE_TOGGLE_MUTATION,
  USER_RATING_QUERY,
  UPDATE_USER_SHARES_MUTATION,
} from '../api/singleArtist'

import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import {
  submitVote,
  updateVoteToggle,
  userUpdateShares
} from '../mutations/singleArtist'

import {
  updateCacheAfterVote,
  updateCacheAfterRating
} from '../cache/singleArtist'


const adminCheck = (user) => {
  if(user && user.roles) {

    if(user.roles.some(role => role.name === "ADMIN")) {
      return true
    }
  }

  return false
}

const handleArtistClick = (artist, currentUser, state, dispatch, history, e, rating) => {
  e && e.stopPropagation()

  let userRating = []
  let newCompareExcludes = state.compareExcludes;
  newCompareExcludes.push(artist.id)
  if(rating) {
    userRating = rating.node.ratings
  } else if(artist.currentUserRatings && artist.currentUserRatings.length) {
    userRating = currentUser.currentUserRatings
  }
  dispatch({payload: {
    artistModal: true,
    originalArtist: artist,
    originalArtistId: artist.id,
    userArtistRatings: [],
    ratings: userRating,
    rateArtistView: false,
    exludes: [artist.id],
    compareExcludes: [artist.id],
  }})
}

const handleRenderGraphic = (artist, ratings, rating, dispatch, history, currentUser, urlRatingGroupId) => {
  dispatch({payload: {artist: artist, ratings: ratings, ratingGroupId: rating.node.id, renderRatingGraphic: true}})
  // history.replace('/user/' + currentUser.id + '/graphic/' + artist.slug + '/' + (urlRatingGroupId ? urlRatingGroupId : rating.node.id))
}

const renderLeftHand = (user, currentUserPage, state, dispatch, history, alert, blur, currentUser, userUpdateMutation, userProfileError, userProfileLoading, allSkills) => {
  const authToken = localStorage.getItem(AUTH_TOKEN)
  if(userProfileLoading) {
    return (
      <div
        style={{
          width: userProfileError && "100%",
          zIndex: (state.renderRatingGraphic || !state.artistModal || !currentUserPage || (state.artistModal && state.smallScreensize)) || !adminCheck(currentUser) ? "0" : "11"
        }}
        className={`user-profile__left-hand skill-step__left-hand${blur ? " blur" : ""}${user && user.userRatingCount < 1 ? " not-user" : ""}`}
      >
        <div className=" user-profile__left-hand__loading-wrapper loading__wrapper">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>
      </div>
    )
  } else if(((!state.smallScreensize) || (state.smallScreensize && state.infoView))) {
    const noArtists = !user.favoriteArtists || user.favoriteArtists.length === 0
    const noSkills = !user.skillRankings || !user.skillRankings.skillRankings || user.skillRankings.skillRankings.length === 0
    return (
      <div
        style={{
          width: userProfileError && "100%",
          zIndex: (state.renderRatingGraphic || !state.artistModal || !currentUserPage || (state.artistModal && state.smallScreensize)) || !adminCheck(currentUser) ? "0" : "11",
        }}
        className={`user-profile__left-hand skill-step__left-hand${blur ? " blur" : ""}${user && user.userRatingCount < 1 ? " not-user" : ""}`}
      >
        <div className="user-profile__heading-bg">
          {currentUserPage && <div className="user-profile__share-wrapper rating-graphic__social-btns">
            <FacebookShareButton
              url={window.location.href}
              quote={`My Rapchr Profile #rapchr`}
              imageUrl={user.image}
              onClick={() => userUpdateShares(userUpdateMutation, currentUser, "Social Profile Share", `Share Profile to Facebook`, "profile share", false, true)}
            >
              {!isMobile && <img src={facebookShareIcon} style={{width: "37px"}}
               />}
               {isMobile && <FacebookIcon size={26}
                 round />}
            </FacebookShareButton >
            <TwitterShareButton
              url={window.location.href}
              title={`My Profile on @rapchr`}
              imageUrl={user.image}
              onClick={() => userUpdateShares(userUpdateMutation, currentUser, "Social Profile Share", `Share Profile to Twitter`, "profile share", false, true)}
            >
              {!isMobile && <img src={twitterShareIcon} style={{width: "37px"}}
               />}
               {isMobile && <TwitterIcon size={26}
                 round />}
            </TwitterShareButton >
            <div className="rating-graphic__copy-icon__wrapper">
              <img
                className={`rating-graphic__copy-icon__image`}
                alt="copy graphic link"
                src={copyIcon}
                onClick={() => {alert.show("Url copied!");navigator.clipboard.writeText(window.location.href)}}
              />
            </div>
          </div>}
          <img className="user-profile__profile-elipse" src={elipse} />
          <div style={{backgroundImage: `url(${user.image ? user.image : logoSingle})`}} className="user-profile__main-image">
          {currentUserPage && <div className="user-profile__share-icon__wrapper">
            <img
              className="user-profile__share-icon__image"
              alt="your personal share link"
              src={shareIcon}
              onClick={() => dispatch({payload: {renderShareModal: true}})}
            />
          </div>}
          </div>
          <p className={`user-profile__user-name${!user.location ? " no-location" : ""}`}>{user.name && formatUserName(user.name)}</p>
          <p className="user-profile__user-location">{user.location && user.location}</p>
            <div className="user-profile__ctas">
              {currentUserPage && <p onClick={() => history.push('/update-profile')} className="user-profile__update-btn">Update</p>}
              {currentUserPage &&
                <p
                  onClick={() => {
                  handleGAEvent("Logout", "User logged out", "header", user.email)
                  localStorage.removeItem(AUTH_TOKEN)
                  window.location.replace('/');
                  }
                }
              className="user-profile__logout-btn">Logout
            </p>
            }
          </div>
        </div>
        <div className={`user-profile__favorites${currentUserPage && isMobile ? " user-profile__favorites__current-user-mobile" : ""}${!currentUserPage ? " no-user" : ""}`}>
          <div className="user-profile__artists-wrapper">
            <p className="user-profile__favorite-title">Favorite Rappers</p>
            <div className="user-profile__artists">
              {noArtists &&
                <p onClick={() => history.push('/update-rappers')} className="user-profile__add-msg">Add Rappers</p>
              }
              {user.favoriteArtists.map((artist, index) => {
                return (
                  <div
                    className={`user-profile__listed-artist${!currentUser ? " no-user" : ""}`}
                    key={index}
                  >
                    <div
                      style={{backgroundImage: `url(${artist.smallImage ? user.smallImage : artist.image})`}}
                      className="user-profile__listed-artist__image">
                    </div>
                    <p className={`user-profile__listed-artist__name${artist.name.length > 18 ? " x-large" : ""}${artist.name.length > 15 ? " large" : ""}`}>{artist.name}</p>
                  </div>
                )
              })}
            </div>
            {currentUserPage && !noArtists && <p onClick={() => history.push('/update-rappers')} className="user-profile__favorites__edit-btn">edit</p>}
          </div>
          <div className="user-profile__skills-wrapper">
            <p className="user-profile__favorite-title">Preferred Skills</p>
            <div className="user-profile__skills">
              {noSkills &&
                <p onClick={() => history.push('/update-skills')} className="user-profile__add-msg">Add Skills</p>
              }
              {user.skillRankings && user.skillRankings.skillRankings && user.skillRankings.skillRankings.map((skill, index) => {
                return (
                  <div
                    key={skill.id}
                    className={`skill-option__wrapper`}
                  >
                    <p className="skill-option__name">{skill.name}</p>
                    <Tooltip profile={true} handleclick={(e) => e.stopPropagation()} allSkills={allSkills} skill={skill} smallScreensize={state.smallScreensize} />
                  </div>
                )
              })}
            </div>
            {currentUserPage && !noSkills && <p onClick={() => history.push('/update-skills')} className="user-profile__favorites__edit-btn">edit</p>}
          </div>
        </div>
        <div className="bump"></div>
        <div onClick={() => state.showUsers ? dispatch({payload: {showUsers: false}}) : dispatch({payload: {showUsers: true}})} className="skill-step__mobile-users__cta">
          <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check Out Our Growing Community" />
          <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
      </div>
    )
  }
}

const renderSearchArea = (state, dispatch, ref) => {
  return (
    <div className="user-profile__search-area">
      <input
        value={state.filter}
        onChange={(e) => dispatch({payload: {filter: e.target.value}})}
        className="fav-artist-step__input user-profile__search-input"
        placeholder="Search..."
        />
      <div onClick={() => dispatch({payload: {showDropdown: !state.showDropdown}})} className="user-profile__filter-wrapper">
        {!isMobile && <p className="user-profile__filter-msg">Sort By: </p>}
        <p className="user-profile__filter-choice">{state.sortType} <span className="user-profile__filter-arrow">{!state.showDropdown ? "⋁" : "⋀"}</span></p>
        {state.showDropdown && <div ref={ref} className="user-profile__filter-dropdown">
          <p onClick={() => dispatch({payload: {showDropdown: false, sortType: "latest"}})} className="user-profile__filter-option">Latest</p>
          <p onClick={() => dispatch({payload: {showDropdown: false, sortType: "earliest"}})} className="user-profile__filter-option">Earliest</p>
        </div>}
      </div>
    </div>
  )
}


const renderRightHand = (ratingGroupsData, ratingGroupsLoading, ratingGroupsError, fetchMore, ref, state, dispatch, history, blur, user, currentUser, currentUserPage, userId, urlRatingGroupId) => {
  if((!state.smallScreensize && ratingGroupsLoading) || (state.smallScreensize && !state.infoView && ratingGroupsLoading)) {
    return (
      <div
        style={{width: ratingGroupsError && "100%", zIndex: state.renderRatingGraphic || state.showUsers ? "-1" : "2"}}
        onScroll={(e) => handleScroll(e, fetchMore, ratingGroupsData, state, dispatch, userId)}
        className={`user-profile__right-hand skill-step__right-hand${state.artistModal ? " with-modal" : ""}`}
      >
        {renderSearchArea(state, dispatch)}
        <div className="loading__wrapper">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>
        <div className="skill-step__bottom-gradient"></div>
        <div className="bump"></div>
      </div>
    )
  } else if(((!state.smallScreensize) || (state.smallScreensize && !state.infoView)) && user) {
    const ratingGroups = ratingGroupsData.ratingGroupsConnection && ratingGroupsData.ratingGroupsConnection.edges ? ratingGroupsData.ratingGroupsConnection.edges : []

    return (
      <div
        onScroll={(e) => handleScroll(e, fetchMore, ratingGroupsData, state, dispatch, userId)}
        className={`user-profile__right-hand skill-step__right-hand${state.artistModal ? " with-modal" : ""}`}
      >
        {renderSearchArea(state, dispatch, ref)}
        <p className="user-profile__right-hand__rating-count">Rating Count: <b>{user.userRatingCount}</b></p>
        {!state.artistModal && ratingGroups && ratingGroups.map((rating, index) => {
          return (
            <ProfileArtist
              customClass={`user-profile__right-hand__artist homepage__right-hand__artist${state.loading ? " loading-more" : ""}${!currentUser ? " no-hover" : ""}`}
              key={index}
              artist={rating.node.artist}
              userRatings={rating.node.ratings}
              currentUser={currentUser}
              profile={true}
              date={formatDate(rating.node.createdAt)}
              renderGraphic={currentUser ? (artist, ratings) => handleRenderGraphic(artist, ratings, rating, dispatch, history, currentUser, urlRatingGroupId) : () => {}}
              smallScreensize={state.smallScreensize}
              openModal={() => {}}
              isMobile={isMobile}
              state={state}
            />
          )
        })}

        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="bump"></div>
        <div className="skill-step__bottom-gradient"></div>
      </div>
    )
  }
}

const renderArtistModal = (ratingGroupsData, state, dispatch, allSkills, artistSlug, userId, history, currentUser) => {

  if((state.originalArtist.id || artistSlug) && state.artistModal) {
    return (
      <ArtistRatingModal
        state={state}
        dispatch={dispatch}
        allSkills={allSkills}
        artistSlug={artistSlug}
        userId={userId}
        history={history}
        currentUser={currentUser}
        sendData={(artistData) => dispatch({payload: {artistName: artistData.singleArtist.name}})}
        profile={true}
        userRatings={state.ratings}
      />
    )
  }
}

const renderUsers = (state, dispatch, recentUsers, history, currentUser, recentUsersLoading, recentUsersError, fetchMore) => {
  if ((state.smallScreensize && state.showUsers)) {
    return (
      <div
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
        className="skill-step__right-hand"
        style={{zIndex: 10}}
      >
       <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
         <img src={signupCommunityMobile} className="skill-step__msg-mobile onboarded" alt="Our Growing Community" />
         <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
       </div>
       <div className="skill-step__top-gradient"></div>
       <div className={`skill-step__users${recentUsersLoading || recentUsersError ? " users-loading" : ""}`}>
         {recentUsersError &&
           <div className="loading__wrapper">
             <p>Error Loading Users</p>
           </div>
         }
         {recentUsersLoading &&
           <div className="loading__wrapper">
             <img className="loading__icon" alt="loading" src={loadingIcon} />
           </div>
         }
         {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
           return (
             <RecommendedUser
               key={index}
               user={user.node}
               artists={true}
               index={index}
               onArtistClick={(artist) => history.push(`/rate/rapper/${artist.slug}`)}
               onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
               recentUsersLoading={recentUsersLoading}
               currentUser={currentUser}
               ratingPage={true}
               customClass={state.loading ? " loading-more" : ""}
             />
           )
         })}
       </div>
       {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
         <img className="loading__icon" alt="loading" src={loadingIcon} />
       </div>}
       <div className="skill-step__bottom-gradient"></div>
       <div className="bump"></div>
     </div>
    )
  }
}

const renderTabs = (state, dispatch) => {
  if(state.smallScreensize) {
    return (
      <div className="user-profile__tab-wrapper">
        <p onClick={() => dispatch({payload: {infoView: true}})} className={`user-profile__tab${state.infoView ? " active" : ""}`}>Info</p>
        <p onClick={() => dispatch({payload: {infoView: false}})} className={`user-profile__tab${!state.infoView ? " active" : ""}`}>Ratings</p>
      </div>
    )
  }
}

const handleGAEvent = (category, action, label, userId) => {
  if(process.env.REACT_APP_PRODUCTION) {
    ReactGA.event({
      userId: userId,
      category: category,
      action: action,
      label: label
    })
  }
}

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const handleScroll = (e, fetchMore, data, state, dispatch, userId) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

  if (bottom) {
    dispatch({payload: {loading: true}})

    fetchMore({
      variables: {
        first: 10,
        filter: state.filter,
        userId: userId,
        ordery: state.sortType,
        cursor: data.ratingGroupsConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.ratingGroupsConnection.edges;
        const pageInfo = fetchMoreResult.ratingGroupsConnection.pageInfo;

        dispatch({payload: {loading: false}})
        return newEdges.length
        ? {
          ratingGroupsConnection: {
            __typename: prevResult.ratingGroupsConnection.__typename,
            edges: [...prevResult.ratingGroupsConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const onLoaderFinished = (currentUser, withGraphic, state, dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})
  alert.show("Updated successfully!")
}

const onResize = (windowSize, state, dispatch) => {
  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showGraphicUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showGraphicUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }

  if(windowSize.windowWidth < "360") {
    dispatch({payload: {xSmallScreensize: true}})
  } else if(windowSize.windowWidth > "360") {
    dispatch({payload: {xSmallScreensize: false}})
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = (allSkills, artistSlug, withGraphic, urlRatingGroupId) => ({
  filter: '',
  compareFilter: '',
  originalArtist: {},
  originalArtistId: null,
  compareArtist: {},
  ...allSkills,
  userArtistRatings: [],
  artistModal: false,
  showUsers: false,
  loadingBarProgress: 0,
  listView: false,
  renderRatingGraphic: false,
  excludes: [],
  compareExcludes: [],
  smallScreensize: window.innerWidth <= 769,
  mediumRatings: window.innerWidth <= 900,
  xSmallScreensize: window.innerWidth <= 360,
  voting: false,
  showGraphicUsers: false,
  infoView: true,
  showDropdown: false,
  sortType: "latest",
  renderShareModal: false,
  loading: false
})

const Profile = ({
  allSkills,
  currentUser,
  match
}) => {
  const artistSlug = match.params.slug && match.params.slug;
  const userId = match.params.userId && match.params.userId;
  const urlRatingGroupId = match.params.ratingGroupId && match.params.ratingGroupId;
  const withGraphic = match.path.includes("graphic")
  const [state, dispatch] = useReducer(reducer, initialState(allSkills, artistSlug, withGraphic, urlRatingGroupId))
  const alert = useAlert();
  let history = useHistory();

  const [createVoteMutation] = useMutation(VOTE_MUTATION)
  const [voteToggleMutation] = useMutation(VOTE_TOGGLE_MUTATION)
  const [userUpdateMutation] = useMutation(UPDATE_USER_SHARES_MUTATION)

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    dispatch({payload: {showDropdown: false}})
  });


  const {
    data: ratingGroupsData,
    loading: ratingGroupsLoading,
    error: ratingGroupsError,
    fetchMore
  } = useQuery(RATING_GROUPS_CONNECTION_QUERY, {
    variables: {
      filter: state.filter,
      first: 10,
      userId: userId,
      orderBy: state.sortType
    }
  })

  const {
    data: userProfileData,
    loading: userProfileLoading,
    error: userProfileError
  } = useQuery(USER_PROFILE_QUERY, {
    variables: {
      userId: userId,
    }
  })

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore: usersFetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  // useEffect(() => {
  //   if(state.compareSearch) {
  //     dispatch({payload: {compareExcludes: [state.originalArtist.id]}})
  //   }
  // }, [state.compareSearch])
  const blur = state.smallScreensize && state.showUsers;

  const currentUserPage = currentUser ? userId === currentUser.id : null

  if(userProfileError) {
    return <ErrorPage />
  } else if(userProfileData || userProfileLoading) {
    const user = userProfileData && userProfileData.userProfile && userProfileData.userProfile;

    return (
      <div className="profile-page user-profile page">
        {user && <Helmet>
          <title>{`${formatUserName(user.name)} Profile | Rapchr`}</title>
          <meta name="description" content={`${user.name}'s' Favorite Rappers and Skill Preferences`} />
          <meta propery="og:url" content={window.location.href} />
          <meta propery="og:title" content={`${formatUserName(user.name)}'s Profile | Rapchr`} />
          <meta propery="og:description" content={`${user.name}'s' Favorite Rappers and Skill Preferences`} />
          <meta propery="og:image"  content={user.image ? user.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
          <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
          <meta name="twitter:title" content={`${formatUserName(user.name)} Profile | Rapchr`} />
          <meta name="twitter:description" content={`${user.name}'s' Favorite Rappers and Skill Preferences`} />
          <meta name="twitter:image" content={user.image ? user.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@joinrapchr" />
        </Helmet>}
        <LoadingBar
           progress={state.loadingBarProgress}
           height={state.loadingBarProgress === 0 ? 0 : 3}
           color={colors.carti} // not working for some reason, in index.css
           onLoaderFinished={() => onLoaderFinished(currentUser, withGraphic, state, dispatch, alert)}
        />
        <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
        {state.renderShareModal == true &&
          <SharePrompt
            currentUser={currentUser}
            state={state}
            dispatch={dispatch}
          />
        }
        {state.renderRatingGraphic &&
          <RatingGraphic
             state={state}
             userId={userId}
             dispatch={dispatch}
             artist={state.artist}
             artistSlug={artistSlug ? artistSlug : state.artist.slug}
             userArtistRatings={state.ratings}
             onVoteClick={(e, ratingGroup, positive) => submitVote(e, createVoteMutation, state.artist, ratingGroup, state.artist.slug, userId, positive, state, dispatch, alert, currentUser, updateCacheAfterVote)}
             onSwitchToggle={(switchOn, setShowVotes, currentUserRatings) => updateVoteToggle(voteToggleMutation, state.artist, state.artist.slug, switchOn, state, dispatch, alert, setShowVotes, currentUserRatings)}
             history={history}
             currentUser={currentUser}
             ratingGroupId={urlRatingGroupId ? urlRatingGroupId : state.ratingGroupId}
             postPage={true}
             artistName={state.artist.name}
           />
        }
        <Header
          currentUser={currentUser}
          customClass={blur && "blur"}
          smallScreensize={state.smallScreensize}
          onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
          ratingPage={true}
          profile={true}
        />
        {user && user.userRatingCount > 0 && renderTabs(state, dispatch)}
        {renderArtistModal(ratingGroupsData, state, dispatch, allSkills, artistSlug, userId, history, currentUser)}
        {renderLeftHand(user, currentUserPage, state, dispatch, history, alert, blur, currentUser, userUpdateMutation, userProfileError, userProfileLoading, allSkills)}
        {user && user.userRatingCount > 0 && renderRightHand(ratingGroupsData, ratingGroupsLoading, ratingGroupsError, fetchMore, ref, state, dispatch, history, blur, user, currentUser, currentUserPage, userId, urlRatingGroupId)}
        {renderUsers(state, dispatch, recentUsers, history, currentUser, recentUsersLoading, recentUsersError, usersFetchMore)}
        <Footer />
      </div>
    )
  } else {
    return (
      <ErrorPage />
    )
  }
}

const RATING_GROUPS_CONNECTION_QUERY = gql`
  query ratingGroupsConnection($userId: ID!, $filter: String, $first: Int, $cursor: String, $orderBy: String) {
    ratingGroupsConnection(userId: $userId, first: $first, filter: $filter, after: $cursor, orderBy: $orderBy) {
      edges {
        cursor
       	node {
          artist {
            id
            name
            image
            scaledImage
            slug
          }
          id
          ratings
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

const USER_PROFILE_QUERY = gql`
  query userProfile($userId: ID!) {
    userProfile(userId: $userId) {
      id
      name
      location
      image
      userRatingCount
      favoriteArtists {
        id
        image
        name
        scaledImage
        slug
        currentUserRatings {
          id
          ratings
        }
      }
      skillRankings {
        skillRankings {
          id
          name
          description
          categories
        }
      }
    }
  }
`




export default Profile
