import React, { useReducer, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";


import {
  FEATURED_ARTISTS_QUERY,
  SIGNUP_MUTATION,
  LOGIN_MUTATION,
  UPDATE_USER_MUTATION,
  RESET_PASSWORD_MUTATION,
  REQUEST_RESET_PASSWORD_MUTATION,
  SPOTIFY_LOGIN_MUTATION,
  FACEBOOK_LOGIN_MUTATION,
  GOOGLE_LOGIN_MUTATION,
  CURRENT_USER_QUERY,
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import {
  updateUserArtists,
  updateUserSkills,
  updateUserProfile,
  submitAuth,
  resetPassword,
  requestReset,
  googleOnSuccess,
  facebookOnSuccess
} from '../mutations/homepage'

import { Helmet } from "react-helmet";

import { AUTH_TOKEN } from '../token'
import { handleArtistClick, productionConsoleCheck } from '../utils'

import FeaturedArtist from '../components/FeaturedArtist';
import Button from '../components/Button';
import Onboarding from '../components/Onboarding';
import Header from '../components/Header'
import Footer from '../components/Footer'
import ArtistRatingModal from '../components/ArtistRatingModal';
import RecommendedUser from '../components/RecommendedUser';

import hompeageMessage from '../images/community-think.svg';
import loadingIcon from '../images/load-disk.png';
import logoSingle from '../images/logo-single.svg'
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import signupCommunityMobile from '../images/growing-community-mobile.svg';
// import rotationIcon from '../images/the-rotation.svg';
import rotationIconBlk from '../images/the-rotation-blk.png';
import spotifyLogo from '../images/spotify-logo.svg';
import appleLogo from '../images/ap-logo.svg';
import tidalLogo from '../images/tidal-logo.svg';



import { colors } from '../styles/defaultTheme';

import WindowSizeListener from 'react-window-size-listener'
import LoadingBar from 'react-top-loading-bar';
import { useAlert } from 'react-alert'

import cloudinaryConfig from '../cloudinaryConfig'
import axios from 'axios';
const cloudName = cloudinaryConfig.cloud_name;


const renderArtistModal = (state, dispatch, allSkills, history, featuredArtists) => {

  if(state.originalArtist.id && state.artistModal) {
    return (
      <ArtistRatingModal
        state={state}
        dispatch={dispatch}
        allSkills={allSkills}
        artistSlug={state.originalArtist.slug}
        homepage={true}
        history={history}
        openSignup={() => dispatch({payload: {signupModal: true, originalArtist: {}, artistModal: false}})}
        featuredArtists={featuredArtists}
      />
    )
  }
}

const renderUsers = (state, dispatch, recentUsers, history, currentUser, recentUsersLoading, recentUsersError, fetchMore) => {
  if ((state.smallScreensize && state.showUsers)) {
    return (
      <div
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
        className="skill-step__right-hand"
        style={{zIndex: 10}}
      >
       <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
         <img src={signupCommunityMobile} className="skill-step__msg-mobile onboarded" alt="Our Growing Community" />
         <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
       </div>
       <div className="skill-step__top-gradient"></div>
       <div className={`skill-step__users${recentUsersLoading || recentUsersError ? " users-loading" : ""}`}>
         {recentUsersError &&
           <div className="loading__wrapper">
             <p>Error Loading Users</p>
           </div>
         }
         {recentUsersLoading &&
           <div className="loading__wrapper">
             <img className="loading__icon" alt="loading" src={loadingIcon} />
           </div>
         }
         {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
           return (
             <RecommendedUser
               key={index}
               user={user.node}
               artists={true}
               index={index}
               onArtistClick={(artist) => handleArtistClick(artist, history)}
               onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
               recentUsersLoading={recentUsersLoading}
               currentUser={currentUser}
               ratingPage={true}
               customClass={state.loading ? " loading-more" : ""}
             />
           )
         })}
       </div>
       {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
         <img className="loading__icon" alt="loading" src={loadingIcon} />
       </div>}
       <div className="skill-step__bottom-gradient"></div>
       <div className="bump"></div>
     </div>
    )
  }
}

const onResize = (windowSize, state, dispatch) => {

  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false}})
  }

  // if(isMobile) {
  //   if(windowSize.windowWidth < "360" && !state.xSmallScreensize && !state.artistModal) {
  //     dispatch({payload: {xSmallScreensize: true}})
  //   } else if(windowSize.windowWidth > "360" && state.xSmallScreensize && !state.artistModal) {
  //     dispatch({payload: {xSmallScreensize: false}})
  //   }
  // } else {
    if(windowSize.windowWidth < "480" && !state.xSmallScreensize && !state.artistModal) {
      dispatch({payload: {xSmallScreensize: true}})
    } else if(windowSize.windowWidth > "480" && state.xSmallScreensize && !state.artistModal) {
      dispatch({payload: {xSmallScreensize: false}})
    }
  // }

}

const handleModalPopup = (dispatch, history, shareUserId) => {
  dispatch({payload: {signupModal: true}})
  if(shareUserId) {
    history.push('/get-access/' + shareUserId)
  } else {
    history.push('/get-access')
  }
}

const onLoaderFinished = (dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})
}


const confirm = async (result, state, dispatch, history) => {

  if(state.login) {
    if(result.data.login) {
      const { token } = result.data.login
      saveUserData(token)
    } else if(result.data.googleLogin) {
      const { token } = result.data.googleLogin
      saveUserData(token)
    } else if(result.data.spotifyLogin) {
      const { token } = result.data.spotifyLogin
      saveUserData(token)
    } else if(result.data.facebookLogin) {
      const { token } = result.data.facebookLogin
      saveUserData(token)
    }
    window.location.replace('/rate')
  } else if(state.resetPassword) {
    const { token } = result.data.resetPassword
    saveUserData(token)
    window.location.replace('/rate')
  } else if(!state.login) {
    const { token } = result.data.signup
    saveUserData(token)
    window.location.replace('/update-skills')
  }
}

const cardhighlight = (e, state, dispatch) => {
  const maxScrollWidth = e.target.scrollWidth - e.target.clientWidth;

  if(state.xAxis >= (maxScrollWidth * .95)) {
    dispatch({payload: {scrollIndex: 9}})
  } else if(state.xAxis >= (maxScrollWidth * .84)) {
    dispatch({payload: {scrollIndex: 8}})
  } else if(state.xAxis >= (maxScrollWidth * .73)) {
    dispatch({payload: {scrollIndex: 7}})
  } else if(state.xAxis >= (maxScrollWidth * .62)) {
    dispatch({payload: {scrollIndex: 6}})
  } else if(state.xAxis >= (maxScrollWidth * .5)) {
    dispatch({payload: {scrollIndex: 5}})
  } else if(state.xAxis >= (maxScrollWidth * .39)) {
    dispatch({payload: {scrollIndex: 4}})
  } else if(state.xAxis >= (maxScrollWidth * .28)) {
    dispatch({payload: {scrollIndex: 3}})
  } else if(state.xAxis >= (maxScrollWidth * .16)) {
    dispatch({payload: {scrollIndex: 2}})
  } else if(state.xAxis >= (maxScrollWidth * .05)) {
    dispatch({payload: {scrollIndex: 1}})
  } else {
    dispatch({payload: {scrollIndex: 0}})
  }
}

const handleScroll = (e, state, dispatch) => {
  dispatch({payload: {xAxis: e.target.scrollLeft}})

  cardhighlight(e, state, dispatch)

  if(e.target.scrollLeft === 0) {
    dispatch({payload: {scrollingX: false}})
  } else if(!state.scrollingX) {
    dispatch({payload: {scrollingX: true}})
  }
}

const handleLeftScroll = (e, state, dispatch) => {
  if(e.target.scrollTop === 0) {
    dispatch({payload: {showMessage: true}})
  } else {
    dispatch({payload: {showMessage: false}})
  }
}

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const handleAuthFailure = (dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})
  alert.removeAll()
  alert.show("Failure Authenticating")
}

const updateCacheAfterAuth = async (store, data, state) => {
  try {
    const queryData = store.readQuery({query: CURRENT_USER_QUERY})
    let newData = ""

    if(data.data.login) {
      newData = data.data.login.user
    } else if(data.data.googleLogin) {
      newData = data.data.googleLogin.user
    } else if(data.data.spotifyLogin) {
      newData = data.data.spotifyLogin.user
    } else if(data.data.facebookLogin) {
      newData = data.data.facebookLogin.user
    } else if(data.data.resetPassword) {
      newData = data.data.resetPassword.user
    } else if(data.data.signup) {
      newData = data.data.signup.user
    }
    let oldData = queryData.currentUser;


    if(newData) {
      oldData.image = newData.image
      oldData.name = newData.name
      oldData.location = newData.location
      oldData.onboardedArtists = newData.onboardedArtists
      oldData.onboardedSkills = newData.onboardedSkills
    }
    store.writeQuery({query: CURRENT_USER_QUERY, data: queryData })
  } catch (e) {
      productionConsoleCheck("Not updating", e)
  }

}

const renderSeoTitle = (loginUrl, signupUrl, updateUrl, state) => {
  if(updateUrl || state.updateModal) {
    return "Update Profile - Rapchr"
  } else if(loginUrl) {
    return "Log In - Rapchr"
  } else if(signupUrl) {
    return "Get Access - Rapchr"
  } else return "Rapchr | The Social Platform For Hip-Hop"
}

const renderFeaturedArtists = (state, featuredArtistData) => {
  if(state.featuredArtists) {
    return state.featuredArtists
  } else if(featuredArtistData && featuredArtistData.artists) {
    return featuredArtistData.artists
  } else return null
}

const saveUserData = token => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = (currentUser, loginUrl, signupUrl, skillUrl, artistUrl, forgetPasswordUrl, updateUrl, resetPasswordToken) => ({
  signupModal: signupUrl || loginUrl || resetPasswordToken || forgetPasswordUrl || updateUrl,
  login: loginUrl,
  topSkillStep: false,
  favArtistStep: false,
  email: updateUrl && currentUser && currentUser.email ? currentUser.email : '',
  location: updateUrl && currentUser && currentUser.location ? currentUser.location : '',
  name: updateUrl && currentUser && currentUser.name ? currentUser.name : '',
  password: '',
  error: false,
  redirect: false,
  showUsers: false,
  smallScreensize: window.innerWidth <= 768,
  xSmallScreensize: window.innerWidth <= 480,
  loadingBarProgress: 0,
  forgetPassword: forgetPasswordUrl,
  confirmPassword: '',
  resetPassword: resetPasswordToken,
  mediumScreensize: window.innerWidth <= 900,
  signUpRedirect: false,
  updateModal: false,
  originalArtist: {},
  artistModal: false,
  userArtistRatings: [],
  scrollingX: false,
  xAxis: 0,
  scrollIndex: 0,
  addImage: false,
  imageHovered: false,
  termIndex: 0,
  filter: "",
  compareFilter: "",
  compareArtist: {},
  featured: [],
  loading: false,
  playlistHovered: false,
  showMessage: true
})

const Home = ({
  currentUser,
  allSkills,
  match
}) => {
  const loginUrl = match.path.includes("login")
  const signupUrl = match.path.includes("get-access") || match.path.includes("signup")
  const skillUrl = match.path.includes("update-skills")
  const artistUrl = match.path.includes("update-rappers")
  const updateUrl = match.path.includes("update-profile")
  const forgetPasswordUrl = match.path.includes("forget-password")
  const resetPasswordToken = match.params.resetPasswordToken && match.params.resetPasswordToken;
  const shareUserId = match.params.userId && match.params.userId;

  const [state, dispatch] = useReducer(reducer, initialState(currentUser, loginUrl, signupUrl, skillUrl, artistUrl, forgetPasswordUrl, updateUrl, resetPasswordToken))
  const [userLoginMutation] = useMutation(LOGIN_MUTATION)
  const [userSignupMutation] = useMutation(SIGNUP_MUTATION)
  const [userUpdateMutation] = useMutation(UPDATE_USER_MUTATION)
  const [requestResetMutation] = useMutation(REQUEST_RESET_PASSWORD_MUTATION)
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION)
  const [spotifyLoginMutation] = useMutation(SPOTIFY_LOGIN_MUTATION)
  const [facebookLoginMutation] = useMutation(FACEBOOK_LOGIN_MUTATION)
  const [googleLoginMutation] = useMutation(GOOGLE_LOGIN_MUTATION)

  const seoTitle = renderSeoTitle(loginUrl, signupUrl, updateUrl, state)

  let history = useHistory();
  const alert = useAlert();

  const {
    data: featuredArtistData,
    loading: featuredArtistsLoading,
    error: featuredArtistsError
  } = useQuery(FEATURED_ARTISTS_QUERY, {
   variables: { featured: true }
  })

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })
  // const terms = ["Debates", "Analysis", "Community", "Takes"]
  const onboarding = currentUser && (!currentUser.onboardedSkills || !currentUser.onboardedArtists)
  const blur = state.signupModal || state.topSkillStep || state.artistModal

  const onboardingRef = useRef(null)

  const executeScroll = isMobile && onboardingRef && onboardingRef.current ? () => window.scrollTo(0, onboardingRef.current.offsetTop) : () => {};

  useEffect(() => {
        let termIndex = state.termIndex;

        const interval = setInterval(() => {
          if(state.termIndex === 3) {
            termIndex = 0
          } else {
            termIndex = state.termIndex + 1
          }
            dispatch({payload: {termIndex: termIndex}})
        }, 3000)

      return () =>  {
        clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }
   }, [state])

  let featuredArtists = renderFeaturedArtists(state, featuredArtistData)

  return (
    <div className="home page">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={"A social platform for music fans to explore and share their taste in hip-hop."} />
        <meta propery="og:title" content={seoTitle} />
        <meta propery="og:url" content={window.location.href} />
        <meta propery="og:description" content="A social platform for music fans to explore and share their taste in hip-hop." />
        <meta propery="og:image"  content="https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png" />
        <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="A social platform for music fans to explore and share their taste in hip-hop." />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:site" content="@joinrapchr" />
        <meta name="twitter:image" content="https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png" />
      </Helmet>
      <LoadingBar
         progress={state.loadingBarProgress}
         height={state.loadingBarProgress === 0 ? 0 : 3}
         color={colors.carti} // not working for some reason, in index.css
         onLoaderFinished={() => onLoaderFinished(dispatch, alert)}
      />
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
      <Header
        currentUser={currentUser}
        history={history}
        dispatch={dispatch}
        state={state}
        customClass={blur && "blur"}
        smallScreensize={state.signupModal ? state.mediumScreensize : state.smallScreensize}
        onboarding={onboarding}
        ratingPage={true}
        homepage={true}
        alert={alert}
        shareUserId={shareUserId}
      />
      <div className="homepage__wrapper">
        <div
          style={{width: featuredArtistsError && "100%"}}
          className={`homepage__left-hand${state.signupModal || state.topSkillStep ? " blur" : ""}`}
          onScroll={(e) => handleLeftScroll(e, state, dispatch)}
        >
          <div>
            {!state.smallScreensize && <div className="homepage__header-message">
              A new space for <div className="homepage__term-container"><span>Rap</span> <div className="homepage__term">
              <span className={`homepage__term-option ${state.termIndex === 0 ? " active" : ""}`}>Debates</span>
              <span className={`homepage__term-option ${state.termIndex === 1 ? " active" : ""}`}>Analysis</span>
              <span className={`homepage__term-option ${state.termIndex === 2 ? " active" : ""}`}>Community</span>
              <span className={`homepage__term-option ${state.termIndex === 3 ? " active" : ""}`}>Takes</span>
              </div></div> Coming Soon!
            </div>}
            {state.smallScreensize && <div className="homepage__header-message">
              A new space for <div className="homepage__term-container"><span>Rap</span><div className="homepage__term-wrapper"><span className="homepage__terms">Debates • Community • Takes • Analysis • Debates</span></div></div>
              <span className="homepage__mobile-text mobile-text">Coming Soon!</span>
            </div>}
            {!state.signupModal && <Button
              customClass="homepage__access-btn"
              handleclick={currentUser ? () =>  history.push('/rate') : () =>  handleModalPopup(dispatch, history, shareUserId)}
              gradient={true}
            >
              {currentUser ? "Start Rating" : "Join Waitlist"}
            </Button>}
          </div>
          <div className="homepage__playlist__wrapper">
            <div className="homepage__playlist__content">
              <p className="homepage__text-message playlist">
              Rapchr is a new social platform for hip-hop fans to explore and express their love for the art form. Discuss and debate with like-minded fans, enjoy new ways to champion your favorite artists, and chronicle your music listening journey, all in one place.
              <b className='homepage__text-message__link' onClick={() =>  handleModalPopup(dispatch, history, shareUserId)}> Join our waitlist to explore this sneak peek of the app!</b>
              </p>
            </div>
          </div>
          <div className="bump"></div>
          {state.mediumScreensize && <div className="bump"></div>}
          <div onClick={() => state.showUsers ? dispatch({payload: {showUsers: false}}) : dispatch({payload: {showUsers: true}})} className="skill-step__mobile-users__cta">
            <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check Out Our Growing Community" />
            <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
          </div>
          <div className="homepage__bottom-gradient"></div>
        </div>
        {!state.signupModal && <img
          style={{display: (featuredArtistsError) && "none"}}
          className={`homepage__middle-msg${blur ? " blur" : ""}${state.scrollingX ? " scrolling-x" : ""}${!state.showMessage ? " scrolling-y" : ""}`}
          src={hompeageMessage}
          alt="What're your favorite rapper's best skills"
        />}
        <div
          onScroll={!state.smallScreensize  ? (e) => handleScroll(e, state, dispatch) : () => {}}
          className={`homepage__right-hand${blur ? " blur" : ""}`}
          style={{display: featuredArtistsError && "none"}}
        >
          {featuredArtistsLoading &&
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          }
          <div className="homepage__featured-artists">
            {featuredArtists && featuredArtists.map((artist, index) => {
              return (
                <FeaturedArtist
                  customClass={`homepage__right-hand__artist${state.scrollIndex == index && !state.smallScreensize ? " current-artist" : ""}`}
                  key={index}
                  artist={artist}
                  currentUser={currentUser}
                  chooseArtist={(artist) => dispatch({payload: {originalArtist: artist, artistModal: true}})}
                  smallScreensize={state.smallScreensize}
                  xSmallScreensize={state.xSmallScreensize}
                />
              )
            })}
            <p className="homepage__featured-artists__msg">
              <b className="homepage__featured-artists__msg__link" onClick={() =>  handleModalPopup(dispatch, history, shareUserId)}>Join the community</b> in analyzing over 600 rappers!
            </p>
          </div>
        </div>
      </div>
      {state.signupModal &&
        <div ref={onboardingRef} className="onboarding__background">
          {state.mediumScreensize && <Header
            currentUser={currentUser}
            history={history}
            dispatch={dispatch}
            state={state}
            smallScreensize={state.mediumScreensize}
            signupScreen={true}
            shareUserId={shareUserId}
          />
          }
          <Onboarding
            currentUser={currentUser}
            authMutation={(e) => submitAuth(e, state, alert, dispatch, userLoginMutation, userSignupMutation, confirm, updateCacheAfterAuth, history, shareUserId)}
            state={state}
            dispatch={dispatch}
            history={history}
            update={updateUrl || state.updateModal}
            requestResetMutation={() => requestReset(requestResetMutation, state, alert, dispatch)}
            resetPasswordMutation={() => resetPassword(resetPasswordMutation, resetPasswordToken, state, alert, dispatch, confirm, updateCacheAfterAuth, history)}
            googleLoginMutation={(response) => googleOnSuccess(response, googleLoginMutation, state, alert, dispatch, confirm, updateCacheAfterAuth, history)}
            facebookLoginMutation={(response) => facebookOnSuccess(response, facebookLoginMutation, state, alert, dispatch, confirm, updateCacheAfterAuth, history)}
            handleAuthFailure={() => handleAuthFailure(dispatch, alert)}
            updateProfileMutation={(e) => updateUserProfile(state, dispatch, alert, userUpdateMutation, currentUser, history, e)}
            executeScroll={executeScroll}
            shareUserId={shareUserId}
          />
        </div>
      }
      {renderArtistModal(state, dispatch, allSkills, history, featuredArtists)}
      {renderUsers(state, dispatch, recentUsers, history, currentUser, recentUsersLoading, recentUsersError, fetchMore)}
      <Footer style={{display: state.signupModal && state.mediumScreensize ? "none" : ""}} customClass={state.signupModal && "modal-footer"} />
    </div>
  )
}

export default Home;
