import React, { useReducer, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import axios from 'axios';
import ReactGA from 'react-ga';

import Header from '../components/Header';
import ArtistRatingModal from '../components/ArtistRatingModal';
import SearchArea from '../components/SearchArea'
import RecommendedUser from '../components/RecommendedUser';
import RatingGraphic from '../components/RatingGraphic';
import ErrorPage from '../components/ErrorPage'
import Footer from '../components/Footer'
import Icon from '../components/Icon/Icon'

import WindowSizeListener from 'react-window-size-listener'
import LoadingBar from 'react-top-loading-bar';

import otherUsersMsg from '../images/our-growing-msg.svg';
import loadingIcon from '../images/load-disk.png';
import mobileFavArtistStepMessage from '../images/our-growing-msg-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import logoSingle from '../images/logo-single.svg'
import sidewaysMsg from '../images/sideways-msg.svg'
import getAcessMsg from '../images/get-access-msg.svg'
import signupCommunityMobile from '../images/growing-community-mobile.svg';
import fullLogo from '../images/full-logo.svg'

import { colors } from '../styles/defaultTheme';
import { handleExcludes } from '../utils'


const renderGraphicUsers = (recentUsers, currentUser, state, dispatch, history) => {
  return recentUsers.recentUsers.map((user, index) => {
    return (
      <RecommendedUser
        key={index}
        user={user}
        currentUser={currentUser}
        onboarding={currentUser && (!currentUser.onboardedSkills || !currentUser.onboardedArtists)}
        artists={true}
        index={index}
      />
    )
  })
}

const renderSeoDesc = (state, withGraphic) => {
  if(withGraphic) {
    return `My ${state.artistName ? state.artistName : state.originalArtist.name} Rapchr Ratings`
  } else if(state.artistName || (state.originalArtist && state.originalArtist.name)) {
    const artistName = state.originalArtist && state.originalArtist.name ? state.originalArtist.name : state.artistName
    return `Give ${artistName} your Rapchr Rating`
  } else return "Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop."
}

const renderSeoTitle = (state, withGraphic) => {
  if(state.artistName || (state.originalArtist && state.originalArtist.name)) {
    const artistName = state.originalArtist && state.originalArtist.name ? state.originalArtist.name : state.artistName
    return `${artistName} | Rapchr`
  } else return "Rapchr"
}

const onResize = (windowSize, state, dispatch) => {
  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showGraphicUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showGraphicUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }

  if(windowSize.windowWidth < "360") {
    dispatch({payload: {xSmallScreensize: true}})
  } else if(windowSize.windowWidth > "360") {
    dispatch({payload: {xSmallScreensize: false}})
  }
}

const onLoaderFinished = (currentUser, withGraphic, state, dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})

  if(!withGraphic && currentUser) {
    alert.removeAll()
    alert.show("Rapchr Rating Submitted!")
  }
}

const NoUserGraphic = ({
  allSkills,
  currentUser,
  withGraphic,
  state,
  dispatch,
  userId,
  artistSlug,
  history
}) => {


  const seoTitle = renderSeoTitle(state)
  const seoDescription = renderSeoDesc(state, withGraphic)
  const seoImage = state.originalArtist ? state.originalArtist.image : logoSingle;

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError
  } = useQuery(RECENT_USERS_QUERY)

  const blur = state.smallScreensize && state.showUsers;

  return (
    <div>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta propery="og:url" content={window.location.href} />
        <meta propery="og:title" content={seoTitle} />
        <meta propery="og:description" content={seoDescription} />
        <meta propery="og:image"  content={state.originalArtist ? state.originalArtist.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
        <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={state.originalArtist ? state.originalArtist.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@joinrapchr" />
      </Helmet>
      <LoadingBar
         progress={state.loadingBarProgress}
         height={state.loadingBarProgress === 0 ? 0 : 3}
         color={colors.carti} // not working for some reason, in index.css
         onLoaderFinished={() => onLoaderFinished(currentUser, withGraphic, state, dispatch, alert)}
      />
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
      <Header
        currentUser={currentUser}
        customClass={blur && "blur"}
        smallScreensize={state.mediumScreensize}
        onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
        ratingPage={true}
        graphicPage={true}
      />
      <div className={`rating-artist__bg${state.showGraphicUsers ? " blur" : ""}`}>
        <img alt="Rapchr" onClick={() => window.location.replace('/')} className="rating-graphic__full-logo" src={fullLogo} />
        <RatingGraphic
           state={state}
           userId={userId}
           dispatch={dispatch}
           artistSlug={artistSlug}
           history={history}
           currentUser={currentUser}
           noUser={withGraphic}
         />
         <img alt="get access" onClick={() => window.location.replace("/get-access")} src={getAcessMsg} className="rating-graphic__get-access-msg" />
       </div>
       {!state.mediumScreensize && state.showGraphicUsers && <Icon
         width="20px"
         height="20px"
         color={"#fff"}
         type="close"
         handleclick={() => dispatch({payload: {showGraphicUsers: false}})}
         customClass="rating-graphic__users-close-icon"
       />}
       {state.showGraphicUsers && recentUsers && recentUsers.recentUsers && !state.mediumScreensize && <img onClick={() => history.push('/')} className="rating-graphic__single-logo" alt="Rapchr" src={logoSingle}  />}
       {state.showGraphicUsers && recentUsersLoading &&
         <div className="rating-graphic__users">
           <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
             <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
             <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
           </div>
           <div className="loading__wrapper">
             <img className="loading__icon" alt="loading" src={loadingIcon} />
           </div>
         </div>
       }
       {state.showGraphicUsers && recentUsersError &&
         <div className="rating-graphic__users">
           <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
             <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
             <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
           </div>
           <div className="loading__wrapper">
             <p>Error Loading Users</p>
           </div>
            <div className="bump"></div>
         </div>
       }
       {state.showGraphicUsers && recentUsers && recentUsers.recentUsers &&
         <div className="rating-graphic__users">
           <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
             <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
             <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
           </div>
            {renderGraphicUsers(recentUsers, currentUser, state, dispatch, history)}
            <div className="bump"></div>
         </div>
       }
       {!state.showGraphicUsers && <img onClick={() => dispatch({payload: {showGraphicUsers: true}})} className="rating-graphic__sideways-msg" src={sidewaysMsg} />}
       {!state.showGraphicUsers &&
        <div style={{display: state.showGraphicUsers && "none"}} onClick={() => dispatch({payload: {showGraphicUsers: true}})} className="rating-graphic__mobile-users__cta">
         <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
         <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
       </div>}
       <Footer customClass={state.artistModal ? "modal-footer artist-modal-footer" : ""} />
     </div>
  )

}

const RECENT_USERS_QUERY = gql`
  query recentUsers {
    recentUsers {
      id
      location
      name
      image
      favoriteArtists {
        id
        name
        image
        smallImage
        scaledImage
        slug
      }
      skillRankings {
        id
        skillRankings {
          id
          name
        }
      }
    }
  }
`

export default NoUserGraphic;
