import React, { useReducer, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import axios from 'axios';
import ReactGA from 'react-ga';

import Header from '../components/Header';
import ArtistRatingModal from '../components/ArtistRatingModal';
import SearchArea from '../components/SearchArea'
import RecommendedUser from '../components/RecommendedUser';
import RatingGraphic from '../components/RatingGraphic';
import ErrorPage from '../components/ErrorPage'
import Footer from '../components/Footer'
import Icon from '../components/Icon/Icon'
import Button from '../components/Button'

import WindowSizeListener from 'react-window-size-listener'
import LoadingBar from 'react-top-loading-bar';

import otherUsersMsg from '../images/signup-community-msg.svg';
import loadingIcon from '../images/load-disk.png';
import mobileFavArtistStepMessage from '../images/growing-community-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import logoSingle from '../images/logo-single.svg'
import sidewaysMsg from '../images/sideways-msg.svg'
import getAcessMsg from '../images/get-access-msg.svg'
import signupCommunityMobile from '../images/growing-community-mobile.svg';
import fullLogo from '../images/full-logo.svg'
import firstRatingMsg from '../images/first-rating-msg.svg'
import firstRatingMsgMobile from '../images/first-rating-msg-mobile.svg'
import favArtistStepMessage from '../images/favorite-artists-msg.svg';

import { colors } from '../styles/defaultTheme';
import { handleExcludes } from '../utils'

import {
  VOTE_MUTATION,
  VOTE_TOGGLE_MUTATION,
  USER_RATING_QUERY
} from '../api/singleArtist'

import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import {
  submitVote,
  updateVoteToggle
} from '../mutations/singleArtist'

import {
  updateCacheAfterVote,
  updateCacheAfterRating
} from '../cache/singleArtist'

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {
    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const renderSearchResultsLimit = (state) => {
  if(isMobile) {
    return 9
  } else return 10

  // else if(state.smallScreensize) {
  //   return 8
}

const renderSignUpButton = (state, history) => {
  return (
    <div className={`rating-artist__signup-btn-wrapper${(state.artistModal && state.smallScreensize || state.artistModal && state.mediumScreensize) ? " modal-open" : ""}`}>
      <Button
        handleclick={() => history.push('/get-access')}
        customClass="rating-artist__signup-btn"
        gradient={true}
      >
        Sign Up To Rate & Explore
      </Button>
    </div>
  )
}

const renderLeftHand = (state, dispatch, history, blur, currentUser, recentUsersError) => {
  return (
    <div
      style={{width: recentUsersError && "100%", zIndex: state.renderRatingGraphic || state.showUsers ? "-1" : "2"}}
      className={`skill-step__left-hand${!currentUser ? " light-blur" : ""}${blur ? " blur" : ""}${state.renderRatingPrompt ? " rating-prompt" : ""}`}
    >
      {state.renderRatingPrompt && !state.smallScreensize &&
        <img
          alt="Choose your first rapper to rate"
          src={firstRatingMsg}
          className="skill-step__left-hand__rating-msg"
        />
      }
      {state.renderRatingPrompt && state.smallScreensize &&
        <img
          alt="Choose your first rapper to rate"
          src={firstRatingMsgMobile}
          className="skill-step__left-hand__rating-msg"
        />
      }
      <div className="fav-artist-step__title-section">
        <h2 className="fav-artist-step__title">Rate <span className="fav-artist-step__highlight">Rap Skills</span></h2>
        <p className={`fav-artist-step__message${state.renderRatingPrompt ? " rating-prompt" : ""}`}>{`Choose from over 600 rappers, ${isMobile ? "tap" : "click"} to rate and share!`}</p>
      </div>
      <SearchArea
        filter={state.filter}
        onFilterChange={currentUser ? (value) => dispatch({payload: {filter: value}}) : () => {}}
        parentDispatch={dispatch}
        clearFilter={() => dispatch({payload: {filter: ""}})}
        onArtistClick={currentUser ? (artist) => handleArtistClick(artist, state, dispatch, history)  : () => {}}
        excludes={state.excludes}
        first={renderSearchResultsLimit(state)}
        smallScreensize={state.smallScreensize}
        isMobile={isMobile}
        compareSearch={state.compareSearch && !state.smallScreensize ? true : null}
        ratePageSearch={true}
        currentUser={currentUser}
      />
      {!state.artistModal && <div style={{display: state.showUsers && "none"}} onClick={() => dispatch({payload: {showUsers: true}})} className="skill-step__mobile-users__cta">
        <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Join Our Growing Community" />
        <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
      </div>}
      {(!state.artistModal && !state.renderRatingPrompt) && <div className="left-hand__bottom-gradient"></div>}
    </div>
  )
}

const renderRightHand = (state, dispatch, history, recentUsers, recentUsersLoading, recentUsersError, currentUser, fetchMore) => {
  if((state.smallScreensize && state.showUsers) || !state.smallScreensize) {
    return (
      <div
        className={`skill-step__right-hand${state.artistModal ? " with-modal" : ""}${state.renderRatingPrompt && state.smallScreensize ? " rating-prompt" : ""}`}
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
        onMouseEnter={() => dispatch({payload: {msgHover: !state.msgHover}})}
        onMouseLeave={() => dispatch({payload: {msgHover: !state.msgHover}})}
      >
        <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
          <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Join Our Growing Community" />
          <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        <div className="skill-step__top-gradient"></div>
        <div
          className={`skill-step__users${recentUsersLoading || recentUsersError ? " users-loading" : ""}`}
        >
          {recentUsersError &&
            <div className="loading__wrapper">
              <p>Error Loading Users</p>
            </div>
          }
          {recentUsersLoading &&
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          }
          {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
            return (
              <RecommendedUser
                key={index}
                user={user.node}
                currentUser={currentUser}
                onboarding={currentUser && (!currentUser.onboardedSkills || !currentUser.onboardedArtists)}
                artists={true}
                index={index}
                ratingPage={true}
                onArtistClick={(artist) => handleArtistClick(artist, state, dispatch, history)}
                customClass={state.loading ? " loading-more" : ""}
              />
            )
          })}
        </div>
        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="skill-step__bottom-gradient"></div>
        <div className="bump"></div>
      </div>
    )
  }
}

const renderArtistModal = (state, dispatch, allSkills, artistSlug, userId, history, currentUser, appState, executeScroll) => {
  if(state.originalArtist.id || artistSlug) {
    return (
      <ArtistRatingModal
        state={state}
        dispatch={dispatch}
        allSkills={allSkills}
        artistSlug={artistSlug}
        userId={userId}
        history={history}
        currentUser={currentUser}
        sendData={(artistData) => dispatch({payload: {artistName: artistData.singleArtist.name, originalArtist: artistData.singleArtist}})}
        appState={appState}
        executeScroll={executeScroll}
        homepage={!currentUser}
      />
    )
  }
}

const renderGraphicUsers = (recentUsers, currentUser, state, dispatch, history) => {
  return recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
    return (
      <RecommendedUser
        key={index}
        user={user.node}
        currentUser={currentUser}
        onboarding={currentUser && (!currentUser.onboardedSkills || !currentUser.onboardedArtists)}
        artists={true}
        index={index}
        onArtistClick={(artist) => handleArtistClick(artist, state, dispatch, history)}
        customClass={state.loading ? " loading-more" : ""}
      />
    )
  })
}

const renderSeoDesc = (state, withGraphic) => {
  const artist = state.artistName || (state.originalArtist && state.originalArtist.name)
  if(withGraphic && artist) {
    return `My ${state.artistName ? state.artistName : state.originalArtist.name} Rapchr Ratings`
  } else if(state.artistName || (state.originalArtist && state.originalArtist.name)) {
    const artistName = state.originalArtist && state.originalArtist.name ? state.originalArtist.name : state.artistName
    return `Give ${artistName} your Rapchr Skill Rating`
  } else return "Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop."
}

const renderSeoTitle = (state, withGraphic) => {
  const artist = state.artistName || (state.originalArtist && state.originalArtist.name)
  if(withGraphic && artist) {
    const artistName = state.originalArtist && state.originalArtist.name ? state.originalArtist.name : state.artistName
    return `My ${artistName} Rapchr Skill Ratings`
  } else if(state.artistName || (state.originalArtist && state.originalArtist.name)) {
    const artistName = state.originalArtist && state.originalArtist.name ? state.originalArtist.name : state.artistName
    return `${artistName}'s Community Skill Ratings | Rapchr`
  } else return "Rapchr | The Social Platform For Hip-Hop"
}

const renderRatingPrompt = (currentUser, state, dispatch) => {
  if(state.renderRatingPrompt && !isMobile) {
    return (
      <div className="rating-prompt__bg">
      </div>
    )
  }
}

const handleArtistClick = (artist, state, dispatch, history) => {
  let newCompareExcludes = state.compareExcludes;
  newCompareExcludes.push(artist.id)

  dispatch({payload: {
    artistModal: true,
    originalArtist: artist,
    originalArtistId: artist.id,
    userArtistRatings: [],
    rateArtistView: false,
    exludes: [artist.id],
    compareExcludes: [artist.id],
    renderRatingPrompt: false,
    userListModal: false,
    compareListChoice: null,
    userListChoice: null
  }})
  history.replace('/rate/rapper/' + artist.slug)
}

const onResize = (windowSize, state, dispatch) => {
  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showGraphicUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showGraphicUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }

  if(windowSize.windowWidth < "360") {
    dispatch({payload: {xSmallScreensize: true}})
  } else if(windowSize.windowWidth > "360") {
    dispatch({payload: {xSmallScreensize: false}})
  }
}

const onLoaderFinished = (currentUser, withGraphic, state, dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})

  if(!withGraphic && currentUser) {
    alert.removeAll()
    alert.show("Rapchr Rating Submitted!")
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = (allSkills, artistSlug, withGraphic, urlFilter, currentUser, history) => ({
  compareSearch: false,
  filter: urlFilter ? urlFilter : '',
  compareFilter: '',
  originalArtist: {},
  originalArtistId: null,
  compareArtist: {},
  ...allSkills,
  userArtistRatings: history.location.state && history.location.state.data ? history.location.state.data : [],
  artistModal: artistSlug ? true : false,
  showUsers: false,
  loadingBarProgress: 0,
  listView: false,
  rateArtistView: history.location.state && history.location.state.from,
  renderRatingGraphic: withGraphic,
  excludes: [],
  compareExcludes: [],
  smallScreensize: window.innerWidth <= 768,
  mediumRatings: window.innerWidth <= 900,
  xSmallScreensize: window.innerWidth <= 360,
  voting: false,
  showGraphicUsers: false,
  graphicRating: null,
  renderRatingPrompt: currentUser && currentUser.userRatingCount == 0 && !artistSlug && !withGraphic,
  msgHover: false,
  ratingMade: false,
  tooltipModal: false,
  userListModal: false,
  userListChoice: null,
  compareListChoice: null,
  loading: false
})

const ArtistRating = ({
  allSkills,
  currentUser,
  match,
  appState
}) => {
  let history = useHistory();
  const artistSlug = match.params.slug && match.params.slug;
  const userId = match.params.userId && match.params.userId;
  const withGraphic = match.path.includes("graphic")
  const urlFilter = match.path.includes("search") ? (new URL(window.location.href)).searchParams.get('q') : null;
  const [state, dispatch] = useReducer(reducer, initialState(allSkills, artistSlug, withGraphic, urlFilter, currentUser, history))
  const alert = useAlert();
  const [createVoteMutation] = useMutation(VOTE_MUTATION)
  const [voteToggleMutation] = useMutation(VOTE_TOGGLE_MUTATION)

  const seoTitle = renderSeoTitle(state, withGraphic)
  const seoDescription = renderSeoDesc(state, withGraphic)
  const seoImage = state.originalArtist ? state.originalArtist.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png";

  const artistModalRef = useRef(null)

  const executeScroll = isMobile && artistModalRef && artistModalRef.current ? () => window.scrollTo(0, artistModalRef.current.offsetTop) : () => {};

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  useEffect(() => {
    if(state.compareSearch) {
      dispatch({payload: {compareExcludes: [state.originalArtist.id]}})
    }
  }, [state.compareSearch])

  const blur = state.smallScreensize && state.showUsers;
  if(withGraphic && !currentUser) {
    return (
      <div>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          <meta propery="og:url" content={window.location.href} />
          <meta propery="og:title" content={seoTitle} />
          <meta propery="og:description" content={seoDescription} />
          <meta propery="og:image"  content={seoImage} />
          <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDescription} />
          <meta name="twitter:image" content={seoImage} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@joinrapchr" />
        </Helmet>
        <LoadingBar
           progress={state.loadingBarProgress}
           height={state.loadingBarProgress === 0 ? 0 : 3}
           color={colors.carti} // not working for some reason, in index.css
           onLoaderFinished={() => onLoaderFinished(currentUser, withGraphic, state, dispatch, alert)}
        />
        <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
        <Header
          currentUser={currentUser}
          customClass={blur && "blur"}
          smallScreensize={state.mediumScreensize}
          onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
          ratingPage={true}
          graphicPage={true}
        />
        <div className={`rating-artist__bg${state.showGraphicUsers ? " blur" : ""}`}>
          <img alt="Rapchr" onClick={() => window.location.replace('/')} className="rating-graphic__full-logo" src={fullLogo} />
          <RatingGraphic
             state={state}
             userId={userId}
             dispatch={dispatch}
             artistSlug={artistSlug}
             onVoteClick={(e, ratingGroupId, positive, artist) => submitVote(e, createVoteMutation, artist, ratingGroupId, artistSlug, userId, positive, state, dispatch, alert, currentUser, updateCacheAfterVote)}
             onSwitchToggle={(switchOn, artist) => updateVoteToggle(voteToggleMutation, artist, artistSlug, switchOn, state, dispatch, alert, updateCacheAfterRating)}
             history={history}
             currentUser={currentUser}
             noUser={withGraphic}
           />
           <img alt="get access" onClick={() => window.location.replace("/get-access")} src={getAcessMsg} className="rating-graphic__get-access-msg" />
         </div>
         {!state.mediumScreensize && state.showGraphicUsers && <Icon
           width="20px"
           height="20px"
           color={"#fff"}
           type="close"
           handleclick={() => dispatch({payload: {showGraphicUsers: false}})}
           customClass="rating-graphic__users-close-icon"
         />}
         {state.showGraphicUsers && recentUsers && recentUsers.recentUsers && !state.mediumScreensize && <img onClick={() => history.push('/')} className="rating-graphic__single-logo" alt="Rapchr" src={logoSingle}  />}
         {state.showGraphicUsers && recentUsersLoading &&
           <div className="rating-graphic__users">
             <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
               <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
               <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
             </div>
             <div className="loading__wrapper">
               <img className="loading__icon" alt="loading" src={loadingIcon} />
             </div>
           </div>
         }
         {state.showGraphicUsers && recentUsersError &&
           <div className="rating-graphic__users">
             <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
               <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
               <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
             </div>
             <div className="loading__wrapper">
               <p>Error Loading Users</p>
             </div>
              <div className="bump"></div>
           </div>
         }
         {state.showGraphicUsers && recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges &&
           <div
            onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
            className="rating-graphic__users"
            >
             <div onClick={() => dispatch({payload: {showGraphicUsers: !state.showGraphicUsers}})} className="rating-graphic__mobile-users__cta__top">
               <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
               <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
             </div>
              {renderGraphicUsers(recentUsers, currentUser, state, dispatch, history)}
              {state.loading && <div className="loading__wrapper user-profile__loading-wrapper graphic-users">
                <img className="loading__icon" alt="loading" src={loadingIcon} />
              </div>}
              <div className="bump"></div>
           </div>
         }
         {!state.showGraphicUsers && <img onClick={() => dispatch({payload: {showGraphicUsers: true}})} className="rating-graphic__sideways-msg" src={sidewaysMsg} />}
         {!state.showGraphicUsers && <div style={{display: state.showGraphicUsers && "none"}} onClick={() => dispatch({payload: {showGraphicUsers: true}})} className="rating-graphic__mobile-users__cta">
           <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
           <img alt={state.showGraphicUsers ? "close" : "open"} src={state.showGraphicUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
         </div>}
         <Footer customClass={state.artistModal ? "modal-footer artist-modal-footer" : ""} />
       </div>
    )
  } else {
    return (
      <div className="rating-page page">
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          <meta propery="og:url" content={window.location.href} />
          <meta propery="og:title" content={seoTitle} />
          <meta propery="og:description" content={seoDescription} />
          <meta propery="og:image"  content={state.originalArtist ? state.originalArtist.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
          <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDescription} />
          <meta name="twitter:image" content={state.originalArtist ? state.originalArtist.image : "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png"} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@joinrapchr" />
        </Helmet>
        <LoadingBar
           progress={state.loadingBarProgress}
           height={state.loadingBarProgress === 0 ? 0 : 3}
           color={colors.carti} // not working for some reason, in index.css
           onLoaderFinished={() => onLoaderFinished(currentUser, withGraphic, state, dispatch, alert)}
        />
        <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
        {currentUser && renderRatingPrompt(currentUser, state, dispatch)}
        <Header
          currentUser={currentUser}
          customClass={blur && "blur"}
          smallScreensize={state.smallScreensize}
          onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
          ratingPage={true}
          ratingMade={state.ratingMade}
        />
        {renderArtistModal(state, dispatch, allSkills, artistSlug, userId, history, currentUser, appState, executeScroll)}
        {!currentUser && renderSignUpButton(state, history)}
        {renderLeftHand(state, dispatch, history, blur, currentUser)}
        <img
          style={{display: recentUsersError && "none"}}
          src={favArtistStepMessage}
          className={`skill-step__middle-msg${state.msgHover ? " hover" : ""}`}
          alt="Join Our Growing Community"
        />
        {renderRightHand(state, dispatch, history, recentUsers, recentUsersLoading, recentUsersError, currentUser, fetchMore)}
        {state.artistModal && <img onClick={() => history.push('/')} className="rating-page__single-logo" alt="Rapchr" src={logoSingle}  />}
        <Footer customClass={state.artistModal && "modal-footer-desktop"} />
      </div>
    )
  }
}

export default ArtistRating;
